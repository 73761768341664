import cherrySlotIcon from './g1485.png'
import diamondSlotIcon from './g1518.png'
import horseshoeSlotIcon from './g1505.png'
import cloverSlotIcon from './g1536.png'
import lemonSlotIcon from './g3498.png'
import plumSlotIcon from './g3392.png'
import spadeSlotIcon from './g1548.png'

export const slotIconSprites = [
  plumSlotIcon,
  //spadeSlotIcon,
  cherrySlotIcon,
  lemonSlotIcon,
  diamondSlotIcon,
  cloverSlotIcon,
  horseshoeSlotIcon,
]
